import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Button from '../components/Button';
import Intro from '../components/Intro';
import Product from '../components/Product';
import SEO from '../components/SEO';

export default function CategoryPage({ pageContext }) {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: {eq: "products"} }) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                description
                name
                link
                image
              }
            }
          }
        }
      }
    }
  `);

  const [productsLoaded, setProductsLoaded] = useState(6);

  const { category } = pageContext;
  const products = allFile.edges;

  const categoryProducts = category.node.childMarkdownRemark.frontmatter.products;
  const filteredProducts = [];

  if (categoryProducts) {
    for (let i = 0; i < categoryProducts.length; i++) {
      for (let j = 0; j < products.length; j++) {
        if (categoryProducts[i] === products[j].node.childMarkdownRemark.frontmatter.name) {
          filteredProducts.push(products[j]);
        }
      }
    }
  }

  const { description, title } = category.node.childMarkdownRemark.frontmatter;

  const onClick = () => {
    trackCustomEvent({
      category: 'Category',
      action: 'click',
      label: `${title} More`,
    });

    setProductsLoaded(productsLoaded + 6);
  };

  const displayedProducts = filteredProducts.slice(0, productsLoaded).map(({ node }) => (
    <Product
      key={node.id}
      node={node}
    />
  ));

  const loadMore = productsLoaded < filteredProducts.length ? (
    <div className="Category__pagination">
      <Button onClick={onClick}>
        View more
      </Button>
    </div>
  ) : null;

  return (
    <Fragment>
      <SEO
        title={title}
        description={description}
      />
      <Intro
        className="Intro--category"
        title={title}
        subtitle={description}
        showButton={false}
      />
      <section className="Category section-padding">
        <div className="grid">
          <div className="Category__container">
            {displayedProducts}
          </div>
          {loadMore}
        </div>
      </section>
    </Fragment>
  );
}

CategoryPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
